import React from 'react';

import cn from 'classnames';
import Button from '../Buttons/PrimaryButton';

import AnimatedTabs from '@trendcapital/loan-calculators-widget/src/components/AnimatedTabs';

import style from './Product.scss';

const Product = ({
  productId,
  product,
  subscribeToPrice,
  currentSub,
  isLoadingSubscribeToPrice,
}) => {
  // const [priceTab, setPriceTab] = React.useState(product.prices[0].id);
  const [price, setCurrentPrice] = React.useState(product.prices[0]);

  const onChangePriceTab = (name, value) => {
    setCurrentPrice(product.prices.filter((f) => f.id === value)[0]);
  };

  const pricesTabs = product.prices.map((price) => {
    return {
      navName:
        price.interval && price.interval_count === 1
          ? price.interval.charAt(0).toUpperCase() + price.interval.slice(1) + 'ly'
          : price.interval && price.interval_count > 1
          ? 'Every ' + price.interval_count + ' ' + price.interval + 's'
          : 'Once',
      value: price.id,
    };
  });

  return (
    <div className="col-12 col-md-6 col-xl-4">
      <div className={style.sub}>
        <div className={style.tabs}>
          <AnimatedTabs
            name="priceTab"
            currentTab={price.id}
            tabList={pricesTabs}
            onChange={onChangePriceTab}
          />
        </div>
        <div className={style.subContent}>
          <div className={style.popular}>
            Most popular
          </div>
          {product.images.map((image) => {
            return <img className={style.productImage} key={image} src={image} />;
          })}
          <h4 className={style.productTitle}>{product.name}</h4>
          {product.description && <p className={style.productDescription}>{product.description}</p>}
          <p></p>
          <div key={price.id} className={style.price}>
            <h3 className={style.priceTitle}>
              {`${new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: price.currency,
              }).format((price.unit_amount / 100).toFixed(2))}`}
            </h3>
            <p className={style.priceInterval}>{price.interval ? 'per ' + price.interval : 'once'}</p>
          </div>
          {currentSub === productId ? (
              <Button isDisabled>Subscribed</Button>
            ) : (
              <Button
                onClick={() => subscribeToPrice(price.id)}
                isLoading={isLoadingSubscribeToPrice}
              >
                Subscribe
              </Button>
            )}
        </div>
      </div>
    </div>
  );
};

export default Product;
