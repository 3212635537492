import React from 'react';

import { withFirebase } from '../../utils/Firebase';
import cn from 'classnames';
import Button from '../Buttons/PrimaryButton';
import Loading from '../Loading';
import Product from '../Product';

import style from './PaymentSettings.scss';

const PaymentSettings = ({ firebase, authUser }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [portalIsLoading, setPortalIsLoading] = React.useState(false);
  const [pricingIsLoading, setPricingIsLoading] = React.useState(true);
  const [products, setProducts] = React.useState([]);
  const [currentSub, setCurrentSub] = React.useState({});
  const [customer, setCustomer] = React.useState({});

  const getCustomerSubscriptions = async () => {
    await firebase.firestore
      .collection('customers')
      .doc(authUser.uid)
      .collection('subscriptions')
      .where('status', 'in', ['trialing', 'active'])
      .onSnapshot(async (snapshot) => {
        if (snapshot.empty) {
          // no subs
        } else {
          setCurrentSub(snapshot.docs[0].data().product.id);
        }
      });
  };

  const getCustomer = async () => {
    await firebase.firestore
      .collection('customers')
      .doc(authUser.uid)
      .onSnapshot(async (snapshot) => {
        if (snapshot.empty) {
          // no customer
        } else {
          setCustomer(snapshot.data());
          // setCurrentSub(snapshot.docs[0].data().product.id);
        }
      });
  };

  const updatePricingPlans = async () => {
    setPricingIsLoading(true);
    const wmpProducts = {};
    const productsSnap = await firebase.firestore
      .collection('products')
      .where('active', '==', true)
      .get();

    const foreachPromises = productsSnap.docs.map(async (productDoc) => {
      const priceSnap = await productDoc.ref
        .collection('prices')
        .where('active', '==', true)
        .orderBy('unit_amount')
        .get();

      const wmpPricingPlans = [];

      priceSnap.docs.forEach((priceDoc) => {
        wmpPricingPlans.push({
          id: priceDoc.id,
          ...priceDoc.data(),
        });
      });

      wmpProducts[productDoc.id] = { prices: wmpPricingPlans, ...productDoc.data() };
    });

    await Promise.all(foreachPromises);

    // console.log('products', wmpProducts);
    setProducts(wmpProducts); //?.sort((a, b) => (a.price > b.price ? 1 : -1)));
    setPricingIsLoading(false);
  };

  React.useEffect(() => {
    if (firebase) {
      updatePricingPlans();
      getCustomerSubscriptions();
      getCustomer();
    }
  }, [firebase]);

  const subscribeToPrice = async (priceId) => {
    setIsLoading(true);

    // Call billing portal function
    const docRef = await firebase.firestore
      .collection('customers')
      .doc(authUser.uid)
      .collection('checkout_sessions')
      .add({
        mode: 'subscription',
        price: priceId, // One-time price created in Stripe
        success_url: window.location.href,
        cancel_url: window.location.href,
      });

    docRef.onSnapshot((snap) => {
      const { error, url } = snap.data();
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
      }
    });
  };

  const redirectToCustomerPortal = async () => {
    //load customer portal
    setPortalIsLoading(true);
    const functionRef = firebase.functions.httpsCallable(
      'ext-firestore-stripe-payments-createPortalLink'
    );

    try {
      const { data } = await functionRef({ returnUrl: window.location.href });

      window.location = data.url;

      setPortalIsLoading(false);
    } catch (e) {
      console.error(e);
      setPortalIsLoading(false);
    }
  };

  return (
    <div className={cn('row', style.subs)}>
      <div className="col-12">
        <h1>Payment settings</h1>
        {customer && customer.stripeId && (
          <div className={style.customer}>
            <h2>Stripe customer portal</h2>
            <p>
              Click the button below to manage your payment details, invoices, and subscriptions in
              one place.
            </p>
            <Button isLoading={portalIsLoading} onClick={redirectToCustomerPortal}>
              Stripe Customer portal
            </Button>
          </div>
        )}
        {currentSub && products[currentSub] && (
          <>
            <h2>Current subscription: </h2>
            <p className={style.currentSub}>{products[currentSub].name}</p>
          </>
        )}
        <h2>Choose your plan:</h2>
      </div>
      {!pricingIsLoading ? (
        Object.entries(products).map(([productId, product]) => (
          <Product key={productId} isLoadingSubscribeToPrice={isLoading} currentSub={currentSub} productId={productId} product={product} subscribeToPrice={subscribeToPrice} />
        ))
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default withFirebase(PaymentSettings);
